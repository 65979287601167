const maps = () => {
    return [
        "albania",
        "afghanistan",
        "algeria",
        "andorra",
        "angola",
        "anguilla",
        "argentina",
        "armenia",
        "australia",
        "austria",
        "azerbaijan",
        "bahamas",
        "bahrain",
        "bangladesh",
        "barbados",
        "belarus",
        "belgium",
        "belize",
        "canada",
        "chile",
        "china",
        "costarica",
        "cyprus",
        "czechrepublic",
        "denmark",
        "ecuador",
        "egypt",
        "estonia",
        "fiji",
        "finland",
        "france",
        "georgia",
        "germany",
        "greece",
        "guatemala",
        "haiti",
        "hongkong",
        "hungary",
        "iceland",
        "india",
        "indonesia",
        "ireland",
        "israel",
        "italy",
        "jamaica",
        "japan",
        "kuwait",
        "laos",
        "luxembourg",
        "madagascar",
]
}

export const randomMap = (maps: any) => {
    const randomIndex = Math.floor(Math.random() * maps.length);
    return maps[randomIndex];
}

export default maps;