import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'


import 'leaflet/dist/leaflet.css';
import './App.css';
import maps, { randomMap } from './data/maps';

interface Guess {
  guess: string;
  result: string[];
}

interface CountGuessesProps {
  wordLength: number;
  maxGuesses: number;
  guesses: Guess[];
}

function CountGuesses({ wordLength, maxGuesses, guesses }: CountGuessesProps) {
  
  const remainingGuesses = maxGuesses - guesses.length;

  return (
    <div className="text-sm m-2">
      <p>
        Guess the {wordLength}-letter country in {maxGuesses} guesses!  
      </p>
      
      {remainingGuesses > 0 && (
        <p>You have {remainingGuesses} guesses remaining</p>  
      )}
      
    </div>
  );
}


function App() {
  const [correct, setCorrect] = useState<boolean>(false);
  const [playMaps, setMaps] = useState<string[]>(maps());
  const [guess, setGuess] = useState<string>('');
  const [guesses, setGuesses] = useState<Guess[]>([]);
  const [correctWord, setCorrectWord] = useState<string>(randomMap(playMaps));
  const imgLoc = `./maps/${correctWord}.svg`

  
  const handleGuess = () => {
    const result = guess.toLowerCase().split('').map((letter, i) => {
      if (letter === correctWord[i]) {
        return 'correct';  
      } 
      if (correctWord.includes(letter)) {
        return 'close';
      }
      return 'absent';
    });
      
    setGuesses([...guesses, { guess, result }]);
    setGuess('');

    if(guess.toLowerCase() == correctWord.toLowerCase()){
      setCorrect(true);
    }
  }

    
  const handleNext = () => {
    // remove correct word from playMaps
    const filtered = playMaps.filter(x => x !== correctWord); 
    setMaps(filtered);
    setCorrectWord(randomMap(filtered));
    setGuesses([]);
    setGuess('');
    setCorrect(false);
  }


  return (
    <div className="App">
      <header className="App-header">
        <div className='w-[230px] rounded-lg p-4'>
          <img className="rounded-xl" src='./logo.jpeg' />
        </div>
        {
          correct && <>
           <div className="animate-pulse text-yellow-500">Correct! {correctWord.toUpperCase()} is the correct answer!</div>
          </>
        }
        {
          playMaps.length > 0 ? (
            <>

        {/* <div >{correctWord}</div> */}
        <div className="flex flex-col md:flex-row ">
          <div className="md:w-[33%] w-[100%]">
            <div className="md:w-[260px] md:h-[360px] pl-5 md:w-[160px] md:h-[360px] text-yellow-500"><img src={imgLoc} /></div>
          </div>
          <div className="md:w-[33%] w-[100%]">
            <CountGuesses 
              wordLength={correctWord.length}
              maxGuesses={6}
              guesses={guesses}
            />
            <div className="text-black-800 px-4">
              <input
                className="bg-slate-500 rounded-md"
                value={guess}
                onChange={e => setGuess(e.target.value)}
              />
              </div>
              <div>
                <div >
                  <button className="rounded-md bg-white text-black p-2 mx-16 my-2 text-base w-[200px]" onClick={handleGuess}>Guess!</button>
                </div>
            </div>
            {guesses.map((wordGuess) => (
              <p>
                {wordGuess.guess.split('').map((letter, i) => {
                  switch(wordGuess.result[i]) {
                    case 'correct':
                      return <span className="text-green-400">{letter}</span>;
                    case 'close':
                      return <span className="text-orange-400">{letter}</span>;
                    default: 
                      return letter;
                  }
                })}
              </p>
            ))}
          </div>
          <div className="md:w-[33%] w-[100%]">
          <MapContainer className="md:w-[400px] md:h-[400px] w-[300px] h-[200px] ml-2 " center={[51.505, -0.09] } zoom={2} scrollWheelZoom={true}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
          </div>
        </div>

        <div >
          <button  className="rounded-md bg-lime-700 text-white text-base px-3 py-1 m-2" onClick={handleNext}>Next!</button>
        </div>
            </>
          ): (
            <>No more maps left :(</>
          )
        }
      </header>
      
    </div>
  );
}

export default App;
